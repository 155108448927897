
import { Icon } from '@components/ui'
import { useState } from 'react'
import s from './BurgerMenu.module.css'
import cn from 'classnames'
import Link from 'next/link'
import { useIntl } from 'react-intl'

const BurgerMenu: React.FC = () => {
    const { formatMessage } = useIntl()
    const t = id => formatMessage({ id })
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='flex'>
            <button className='outline-none z-30' onClick={() => setIsOpen(!isOpen)}>
                <div className={cn("text-white text-2xl px-2", s.menuIcon, { [s.isOpen]: isOpen })}>
                    <Icon icon="icon-menu" className={cn("text-white text-2xl")}></Icon>
                </div>
            </button>
            {
                isOpen && (
                    <div className='absolute w-screen h-screen inset-0 pt-32 z-20 bg-black bg-opacity-90 flex flex-col items-stretch'>
                        <Link href='/'>
                            <div className='px-4 py-2 text-white text-xl font-medium flex flex-row' onClick={() => setIsOpen(false)}>
                                <i className='icon-icon_Home1 text-white text-xl' />
                                <div className='text-white text-xl font-medium pl-2'>
                                    {t("Home")}
                                </div>
                            </div>
                        </Link>
                        <Link href='/order'>
                            <div className='px-4 py-2 text-white text-xl font-medium flex flex-row' onClick={() => setIsOpen(false)}>
                                <i className='icon-Icon_Orders text-white text-xl' />
                                <div className='text-white text-xl font-medium pl-2'>
                                    {t("Order")}
                                </div>
                            </div>
                        </Link>
                        <div tabIndex={0} className="collapse w-full  collapse-arrow">
                            <div className='collapse-title  px-4 text-white text-xl font-medium flex flex-row'>
                                <i className='icon-Icon_Products1 text-white text-xl' />
                                <div className='text-white text-xl font-medium pl-2'>
                                    {t("Product")}
                                </div>
                            </div>
                            <div className="collapse-content">
                                <Link href='/product'>
                                    <div className='text-color99 text-xl font-medium pl-6 ml-1 py-2' onClick={() => setIsOpen(false)}>
                                        {t("Item")}
                                    </div>
                                </Link>
                                <Link href='/collection'>
                                    <div className='text-color99 text-xl font-medium pl-6 ml-1 py-2' onClick={() => setIsOpen(false)}>
                                        {t("Collection")}
                                    </div>
                                </Link>
                                <Link href='/asset'>
                                    <div className='text-color99 text-xl font-medium pl-6 ml-1 py-2' onClick={() => setIsOpen(false)}>
                                        {t("Asset")}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <Link href='/advertisement'>
                            <div className='px-4 py-2 text-white text-xl font-medium flex flex-row' onClick={() => setIsOpen(false)}>
                                <i className='icon-Icon_Products1 text-white text-xl' />
                                <div className='text-white text-xl font-medium pl-2'>
                                    {t("Advertisement")}
                                </div>
                            </div>
                        </Link>

                        <Link href='/report'>
                            <div className='px-4 py-2 text-white text-xl font-medium flex flex-row' onClick={() => setIsOpen(false)}>
                                <i className='icon-Icon_Report text-white text-xl' />
                                <div className='text-white text-xl font-medium pl-2'>
                                    {t("Report")}
                                </div>
                            </div>
                        </Link>

                        <div tabIndex={0} className="collapse w-full  collapse-arrow">
                            <div className='collapse-title  px-4 text-white text-xl font-medium flex flex-row'>
                                <i className='icon-Icon_Team text-white text-xl' />
                                <div className='text-white text-xl font-medium pl-2'>
                                    {t("Member")}
                                </div>
                            </div>
                            <div className="collapse-content">
                                <Link href='/member'>
                                    <div className='text-color99 text-xl font-medium pl-6 ml-1 py-2' onClick={() => setIsOpen(false)}>
                                        {t("Member")}
                                    </div>
                                </Link>
                                <Link href='/giftcard'>
                                    <div className='text-color99 text-xl font-medium pl-6 ml-1 py-2' onClick={() => setIsOpen(false)}>
                                        {t("Giftcard")}
                                    </div>
                                </Link>
                            </div>
                        </div>



                        <Link href='/shipping-rate'>
                            <div className='px-4 py-2 text-white text-xl font-medium flex flex-row' onClick={() => setIsOpen(false)}>
                                <i className='icon-Icon_Delivery text-white text-xl' />
                                <div className='text-white text-xl font-medium pl-2'>
                                    {t("Delivery")}
                                </div>
                            </div>
                        </Link>

                    </div>
                )
            }
        </div>
    )

}
export default BurgerMenu;
