export const zh = {
  'Search product': '搜索商品',
  'About Us': '关于我们',
  'Mobile App': '手机软件',
  Service: '服务',
  Legal: '法规',
  Share: '分享',
  '2018 RORO Holding Inc. All rights reserved.':
    '2018 RORO Holding Inc. 保留所有权利.',
  'Free delivery': '全城免运费',
  more: '更多',
  'Our price': '平台价',
  'Market price': '市场价',
  'Add to cart': '加入购物车',
  Description: '描述',
  FOOD: '食品',
  PARENTING: '亲子美妆',
  'BEST DEAL': '每日尖货',
  'RORO CHOICE': '肉肉精选',
  'App Download': 'App下载',
  Search: '搜索',
  'Our story': '我们的故事',
  Business: '商业合作',
  Career: '工作机会',
  'Sign in/Sign up': '登录/注册',
  'Please enter your phone number to join us!':
    '请输入您的手机号码来加入我们！',
  'Unregistered phone number will enter to sign up page':
    '未注册的手机号码将前往注册页面',
  'Please enter phone number': '请输入手机号码',
  'Please enter valid phone number': '请输入有效的电话号码',
  Next: '下一步',
  'Verify number': '验证手机号',
  'Verification code sent! please enter it below.': '验证码已发送, 请输入',
  'By continuing you agree to our Terms & Conditions and Online Policies.':
    '继续操作，即表示您同意我们的条款和政策。',
  'Set up password': '设置密码',
  'New password': '新密码',
  'Re-type new password': '重新输入新密码',
  'Please enter at lease 6 characters with number, Upper letter, lower letter.':
    '请输入至少6个字符，包括数字，大写字母，小写字母。',
  'Invaild password you entered, please try agian': '您输入的密码无效，请重试',
  'Sign in': '登录',
  'Enter password below to sign in': '在下面输入密码登录',
  'Sign in with Dynamic Code': '用验证码登录',
  'Forget password': '忘记密码',
  'Enter password': '输入密码',
  Error: '错误',
  Confirm: '确定',
  'An unknown error occurred on the server': '服务器发生未知错误',
  'Sold out': '待补',
  'Maximum inventory reached': '已到达最大库存',
  'Seems no items in you cart...': '您的购物车中似乎没有物品...',
  'Shop now': '开始购物',
  Cart: '购物车',
  items: '商品',
  Remove: '删除',
  Contact: '联系方式',
  Name: '姓名',
  Phone: '手机',
  Email: '邮箱',
  Address: '地址',
  'Please enter contact name': '请输入您的姓名',
  'Please enter contact phone': '请输入您的手机号',
  'Please enter email': '请输入您的邮箱',
  'Please enter your address': '请输入您的地址',
  City: '市',
  'Postal Code': '邮编',
  'Check out': '下单',
  'Shipping address': '收货地址',
  Shipping: '运费',
  'Your Credit': '用户余额',
  Subtotal: '小计',
  PST: 'PST',
  GST: 'GST',
  Total: '总价',
  Note: '备注',
  Order: '订单',
  'Use credit': '消费用户余额',
  'If you have any requirements, please leave a message.':
    '如果您有任何要求，请在这里留言。',
  'The post code you entered is': '您输入的邮编是',
  'please confirm that the entry is correct.': '请确认输入的邮编正确。',
  'The total amount of goods in the shopping cart is less than':
    '购物车里商品的总金额不足',
  'and free shipping must be over': '免运费需满',
  Apply: '使用',
  Undo: '撤销',
  'Payment success': '支付成功',
  'Go home': '回到主页',
  'Choose payment method': '选择支付方式',
  'WeChat Pay': '微信支付',
  'Credit Card': '信用卡',
  'Card Number': '信用卡号码',
  'Card Holder': '持卡人姓名',
  Expiry: '有效期',
  CVC: 'CVC',
  'Save this card informations': '保存以上卡信息，下次付款无需输入',
  Pay: '添加信用卡并支付',
  'Use previous credit card': '使用信用卡',
  'Add new credit card': '添加新的信用卡',
  'Scan the QR Code below by turn on WeChat QR scanner to compelete payment.':
    '用微信应用扫描下面的二维码以完成付款.',
  'QR Code Should Show Up Within 30 Seconds, Condition May Vary Depending On Internet Speed':
    '二维码刷新中。如果超过30秒还未出现，请刷新页面.',
  'Other payment method': '其他支付方式',
  Orders: '订单',
  Password: '密码',
  Profile: '个人信息',
  'RORO VIP': 'RORO VIP计划',
  Redeem: '充值',
  Giftcard: '礼品卡',
  Referral: '推荐',
  'Sign out': '登出',
  WELCOME: '欢迎',
  Save: '保存',
  Birthday: '生日',
  'Your credit': '您的用户余额',
  'ROROHOME credit can be used for all items purchase in ROROHOME online store.':
    'ROROHOME用户余额可用于在ROROHOME在线商店中购买所有商品。',
  'All redeemed reward are nonrefundable, questions go to ':
    '所有兑换的礼品卡均不可退款，有问题？',
  'learn more>': '了解更多>',
  'Enter your gift card PIN': '输入您的礼品卡PIN码',
  Redeem: '兑换礼品卡',
  'Send your friend an exclusive invitation link first. If your friend signs up for ROROHOME.com through your invitation link, your friend will receive 100 points immediately. Also, if this friend completes his/her first order on ROROHOME, you can also earn 100 points when the order ships! Please check the invitation record for details. For your reward points, please verify your email firstly.':
    '向您的朋友发送您的专属邀请链接。 如果您的朋友通过邀请链接注册ROROHOME.com，您的朋友将立即获得100点积分。 另外，如果您的朋友在ROROHOME上完成了他/她的第一笔订单，则在订单发货时您还可以获得100点积分！ 请查看邀请记录以了解详细信息。 为了获得奖励积分，请先验证您的电子邮件。',
  'Send invitation': '发送邀请',
  Copy: '复制',
  Copied: '已复制',
  'Your current credits': '您现在的积分',
  User: '用户',
  'Locked Rewards': '奖励',
  'ROROHOME has established a membership growth project, aiming at providing a better user experience for loyal customers. Each customer will get corresponding points for every consumption in ROROHOME, and the user level will be upgraded as they reach a certain amount of points, and we will give upgraded users more discounts and rewards.':
    'ROROHOME的会员VIP成长计划，旨在为忠实客户提供更好的用户体验。 每个客户在ROROHOME中的每次消费都将获得相应的积 分，达到一定积分后，用户级别将得到升级，我们将为升级的用户提供更多的折扣和奖励。',
  'Birthday Surprise': '生日惊喜',
  'Seasonal Gifts': '季节礼物',
  'VIP Discount': 'VIP折扣',
  Locked: '待解锁',
  Unlocked: '已解锁',
  'Tire unlock': '解锁等级',
  Detail: '详细',
  'Members have to Fill in personal information under a personal account before receiving a birthday gift. The gift will be different in different months and VIP levels, and can only be claimed and used in that month. Members will get different level-up celebration gifts when the level is upgraded.':
    '请先在个人中心完善个人信息，再前往会员中心领取，每年每人限领取一次，不同VIP等级礼物有所不同，具体礼物也因时间不同而 不同。生日礼物将在用户生日当月可领取可使用。同时用户升级时可以立刻激活升级礼物，不同等级升级分别获取不同价值的礼物。',
  'You will receive a special gift in every different season. The gift will be different in different months and VIP levels.':
    '每个不同的季度您都会收到一份特别的礼物。不同VIP等级礼物有所不同，具体礼物也因时间不同而不同。',
  'Users will enjoy a 5% discount on all products.':
    '用户将享受全场95折的折扣。',
  'Tier Upgrade Gift': '升级礼物',
  'Gift card': '礼品卡',
  'Payment methods': '付款说明',
  'Shipping policy': '物流政策',
  'Term of use': '服务条款',
  Privacy: '隐私条款',
  'Return policy': '退款政策',
  Disclaimer: '免责声明',
  'iOS App': 'iOS应用',
  'Android App': '安卓应用',
  'The Page you are looking for has been taken...':
    '您正在寻找的页面被外星人带走了...',
  'Try again': '再试一下',
  'ROROHOME is an online fresh food shopping app. We provides high-quality fresh meat, seafood, vegetables, fruits and more every day. Allows you to experience a high-quality life at home.':
    'ROROHOME是在线生鲜购物应用。我们每天提供优质的新鲜肉类，海鲜，蔬菜，水果等。让您在家中体验高品质的生活。',
  Comment: '评论',
  'Please complete the following options, left and submit your comments. Our custmer service will respond by email as soon as posible.':
    '请完成以下选项，留下并提交您的评论。 我们的客服将尽快通过电子邮件回复。',
  'Please left your comment here …': '请在这里留下您的留言...',
  'We have received your feedback!': '我们已收到您的反馈!',
  'Contact us': '联系我们',
  'Empty orders list': '您还没有完成的订单',
  'Scan QR code and contact us by WeChat': '微信扫描二维码联系我们',
  All: '全部',
  'PRICE RANGE': '价格区间',
  Popular: '热门',
  Price: '单价',
  DASHBOARD: '用户中心',
  Account: '账号',
  'Redeem/Giftcard': '充值/礼品卡',
  'Subscribe & Save': '订阅',
  fasterAndOnTime:
    '更快，更准时，我们永远不会忘记您的需求。选择下面的订阅包立即订阅并获得 5% 至 8% 的折扣。',
  Subscribe: '订阅',
  Subscribed: '已订阅',
  Unsubscribed: '取消订阅',
  'Choose subscribe plan': '选择订阅计划',
  Weekly: '周',
  'Choose delivery time': '选择送货时间',
  Mon: '周一',
  Tue: '周二',
  Wed: '周三',
  Thu: '周四',
  Fri: '周五',
  Including: '包含',
  onTimeDelivery:
    '定期送货日期既订购服务当天的次日。如需更改送货日期请在下单页面的备注里写下备注，或者与ROROHOME客服联系更改。',
  'Start date': '开始时间',
  'Delivery time': '送货时间',
  Plan: '计划',
  Contract: '合约',
  'Change plan': '变更计划',
  pleaseChangePlan15:
    '请在下一个送货日期前 15 天更改计划。 我们的客服可能会与您联系确认。',
  Later: '以后',
  youSubscribeServiceWillEnd: '您的订阅服务将结束。 我们希望以后再见。',
  Ending: '即将结束',
  'Re-active subscribe plan': '重新激活订阅计划',
  onceYouConfirmReactive: '一旦您确认重新激活计划，您的订阅计划将继续收费',
  'Plan re-active': '重新激活计划',
  welcomeBackYourSubscribePlan:
    '欢迎回来！ 您的订阅计划已重新激活。 我们将为您提供更好的服务。',
  'Pack including:': '订阅包包含:',
  Expired: '已过期',
  'Choose a plan': '选择一个计划',
  Monthly: '月',
  'Please choose a subscribe plan and delivery time.':
    '请选择订阅计划和送货时间。',
  'Plan delivery at': '送货时间',
  'Order Summary': '订单概要',
  'Continue to check out': '继续去支付',
  Pending: '待支付',
  Canceled: '已取消',
  'To Pay': '去支付',
  'Plan changed': '计划已变更',
  Unsubscribe: '取消订阅',
  'Please choose a plan you want to unsubscribe': '请选择您要退订的计划',
  'We are sorry to losing you': '我们很抱歉您选择取消订阅',
  'Your subscribe service will be end.': '您的订阅服务将结束。',
  'The network connection is abnormal, please try again later.':
    'The network connection is abnormal, please try again later.',
  'The network you are using cannot connect to our server, please check the network connection settings.':
    '网络连接异常，请稍后重试。',
  welcomeBack: '欢迎回来！ 您的订阅计划已重新激活。 我们将为您提供更好的服务。',
  creditCardAreSecurely:
    '信用卡支付技术由 Stripe 提供。 所有信用卡均通过 Stripe 安全处理。',
  Unpaid: '待支付',
  'Continue to pay': '继续去支付',
  'Discard this order': '取消订单',
  'Enter email': '输入邮箱',
  ROROHOME: 'ROROHOME',
  Today: '今天',
  '7 days': '7天',
  '30 days': '30天',
  'Total Orders': '总订单',
  'All Orders': '全部订单',
  'Unfulfilled orders': '未配送订单',
  'Payment status': '支付',
  'Delivery status': '配送',
  Paid: '已支付',
  Fulfilled: '已配送',
  Unfulfilled: '未配送',
  Refund: '退款',
  'Print Receipt': '打印收据',
  'Order Info': '订单信息',
  'Delivery Info': '配送信息',
  'Order Time': '订单时间',
  'Point Used': '积分兑换',
  'Shipping Method': '配送方式',
  'Customer Message': '顾客留言',
  'Billing Address': '账单地址',
  'Shipping Address': '送货地址',
  'Same as shipping address': '与送货地址相同',
  Items: '商品',
  'Refund money': '退金额',
  'Refund point': '退积分',
  'Refund amount': '退款数量',
  'Refund reason': '退款理由',
  'Add refund reason': '添加退款理由',
  'Refund type': '退款类型',
  Missing: '缺少',
  Success: '提交成功',
  'Publish Status': '上架状态',
  Sort: '排序方式',
  Stock: '可用状态',
  Published: '上架中',
  Unpublished: '下架中',
  'Time descending': '时间最近',
  'Time ascending': '时间最远',
  'Price ascending': '价格升序',
  'Price descending': '价格降序',
  'In Stock': '有货',
  'Out of Stock': '售空',
  Inventory: '库存',
  'Expired At': '有效期',
  'Image URL': '图片 URL',
  'Product detail': '商品详情',
  'Product name': '商品名字',
  Vendor: '供货商',
  'Product type': '商品类型',
  Tag: '标签',
  'Sort value': '排序值',
  Taxes: '税',
  Excerpt: '简介',
  Variants: '产品子类',
  Options: '产品选项',
  'Option name': '选项名',
  'Click to add': '点击添加',
  Values: '值',
  Option: '选项',
  ID: 'ID',
  'Option 1': '选项 1',
  'Option 2': '选项 2',
  'Option 3': '选项 3',
  'Compared Price': '市场价格',
  'Cost Price': '成本价格',
  Price: '售价',
  'Service Charge': '服务费',
  'Service Title': '服务名称',
  Image: '图片',
  Size: '尺寸',
  'Inventory Count': '库存计数',
  'New Product': '新产品',
  'Icon URL': '图标 URL',
  'Banner URL': '横幅 URL',
  'Collection detail': '集合详情',
  'Collection name': '集合名',
  'Chinese name': '中文名',
  Type: '类型',
  'Meet all conditions': '符合全部条件',
  Conditions: '条件',
  Condition: '条件',
  Attribute: '属性',
  Operator: '操作符',
  Value: '值',
  'New Collection': '新集合',
  'Min expense': '最小花费',
  'Service level': '服务等级',
  'Export to CSV': '导出CSV',
  'Delivery name': '配送名称',
  'Exclude Postal Code': '不包含邮编',
  'New Shipping Rate': '新配送',
  Balance: '余额',
  Point: '积分',
  'User Info': '用户信息',
  Subscribed: '已订阅',
  'Choose Image': '选择图片',
  Submit: '提交',
  'No file chosen': '未选择文件',
  Copy: '拷贝',
  Copied: '已拷贝',
  'Select a file': '选择一个文件',
  Close: '关闭',
  'Please input 4 Gift Card#': '请输入4位礼品卡号#',
  Check: '查看',
  true: 'true',
  false: 'false',
  Qty: '数量',
  'Products purchases': '产品购买',
  'TOP 20': 'TOP 20',
  Home: '主页',
  Product: '产品',
  Item: '商品',
  Collection: '集合',
  Asset: '文件',
  Report: '报告',
  Member: '会员',
  Delivery: '配送',
  'Giftcard Used': "礼品卡使用",
  "signOut": "登出",
  "changePassword": "更改密码",
  "changeStore": "选择商店",
  "next": "下一步",
  "chooseStore": "选择商店",
  "chooseAndClickStore": "选择并点击下方商店，然后点击“更换”按钮进入管理",
  "account": "账号",
  "store": "商店",
  "enterNewPassword": "输入新密码",
  "enterNewPasswordAgain": "再次输入新密码",
  "forgetPassword": "忘记密码?",
  "findPassword": "找回密码",
  "haveSentCode": "我们已经发送一封邮件到您的邮箱, 请在下方输入您收到的验证码。",
  "Product ID": "Product ID",
  "Collection ID": "Collection ID",
  "Advertisement": "广告",
  "Image URL": "Image URL",
  "Chinese Description": "Chinese Description",
  "Location": "Location",
  "Description": "Description",
  "Title": "Title",
  "URL": "URL",
  "Detail": "Detail",
  "Position": "Position",
  "New Advertisement": "New Advertisement",
}

