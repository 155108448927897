import '@assets/main.css'
import '@assets/icons.css'
import '@assets/DateRangePicker.css'
import '@assets/DatePicker.css';
import 'react-calendar/dist/Calendar.css'
import 'keen-slider/keen-slider.min.css'
import 'react-smartbanner/dist/main.css';


import { ManagedUIContext } from '@components/ui/context'
import { Head } from '@components/common'

import type { AppProps } from 'next/app'
import { FC } from 'react'

import * as locales from '@i18n'
import { IntlProvider } from "react-intl"
import { useRouter } from "next/router"
import * as server from '@lib/server'

const Noop: FC = ({ children }) => <>{children}</>

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const { locale, defaultLocale, pathname } = router
  const localeCopy = locales[locale]
  const messages = localeCopy//localeCopy[pathname]

  const Layout = (Component as any).Layout || Noop

  return (
    <>
      <Head />

      <ManagedUIContext>
        <IntlProvider
          locale={locale}
          defaultLocale={defaultLocale}
          messages={messages}
        >
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </IntlProvider>
      </ManagedUIContext>
    </>
  )


}

export default MyApp
