
import { FC } from 'react'
import Link from 'next/link'
import { UserNav, BurgerMenu } from '@components/common'
import { Icon } from '@components/ui'
import { useIntl } from "react-intl"
import { useRouter } from 'next/router'

const Navbar: FC = () => {
    const { formatMessage } = useIntl()
    const t = id => formatMessage({ id })

    const router = useRouter()

    const pathname = router.pathname;
    const query = router.query;

    return (
        <div className={"sticky top-0 z-20 bg-color33 py-2 flex flex-col"}>
            <div className="pt-2 pr-2 flex flex-row items-center justify-between">
                <BurgerMenu></BurgerMenu>
                <div className='text-white text-sm font-medium'>
                    {t(getTitle(pathname, query))}
                </div>
                <div className="dropdown dropdown-end">
                    <button className="dropbtn">
                        <img src={"/assets/user.png"} className={"object-cover w-6 h-6 rounded-full mr-2"} />
                    </button>
                    <ul className="dropdown-content p-2 mt-4 shadow menu w-40 bg-white">
                        <li>
                            <Link href='/login/setpassword'>
                                <a className='text-sm p-2'>{t('changePassword')}</a>
                            </Link>
                        </li>
                        <li>
                            <Link href='/login/store'>
                                <a className='text-sm p-2'>{t('changeStore')}</a>
                            </Link>
                        </li>
                        <li>
                            <button className='text-sm p-2 text-left' onClick={() => {
                                console.log("sign")
                                localStorage.clear();
                                router.replace({ pathname: '/login/email' })
                            }}>{t('signOut')}</button>

                        </li>

                    </ul>
                </div>

            </div>
        </div >
    )
}

const getTitle = (pathname: string, query?: any): string => {
    if (pathname == '/') {
        return new Date().toLocaleDateString()
    }
    if (pathname == '/order') {
        return 'Order'
    }
    if (pathname == '/order/all') {
        return 'All Orders'
    }
    if (pathname == '/order/detail' || pathname == '/order/refund') {
        return (query && query.id) ? '#' + query.id.substr(0, 8) : 'ROROHOME'
    }
    if (pathname == '/product' || pathname == '/product/detail' || pathname == '/product/new') {
        return 'Product'
    }
    if (pathname == '/collection' || pathname == '/collection/detail' || pathname == '/collection/new') {
        return 'Collection'
    }
    if (pathname == '/asset') {
        return 'Asset'
    }
    if (pathname == '/report') {
        return 'Report'
    }

    if (pathname == '/member' || pathname.startsWith('/member/detail')) {
        return 'Member'
    }

    if (pathname == '/giftcard') {
        return 'Giftcard'
    }

    if (pathname.startsWith('/shipping-rate')) {
        return 'Delivery'
    }

    return 'ROROHOME'
}

export default Navbar
