import { FC, useRef, useEffect } from 'react'
import Portal from '@reach/portal'
import s from './Dialog.module.css'
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock'

interface Props {
    open?: boolean,
    children?: any
}

const Dialog: FC<Props> = ({ open, children }) => {
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>

    useEffect(() => {
        if (ref.current) {
            if (open) {
                disableBodyScroll(ref.current)
            } else {
                enableBodyScroll(ref.current)
            }
        }
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [open])

    return (
        <Portal>
            {open ? (
                <div className={s.root} ref={ref}>
                    <div className={s.modal}>
                        {children}
                    </div>
                </div>
            ) : null}
        </Portal>
    )
}

export default Dialog
