export const en = {
  'Search product': 'Search product',
  'About Us': 'About Us',
  'Mobile App': 'Mobile App',
  Service: 'Service',
  Legal: 'Legal',
  Share: 'Share',
  '2018 RORO Holding Inc. All rights reserved.':
    '2018 RORO Holding Inc. All rights reserved.',
  'Free delivery': 'Free delivery depend by area',
  more: 'more',
  'Our price': 'Our price',
  'Market price': 'Market price',
  'Add to cart': 'Add to cart',
  Description: 'Description',
  FOOD: 'FOOD',
  PARENTING: 'PARENTING',
  'BEST DEAL': 'BEST DEAL',
  'RORO CHOICE': 'RORO CHOICE',
  'App Download': 'App Download',
  Search: 'Search',
  'Our story': 'Our story',
  Business: 'Business',
  Career: 'Career',
  'Sign in/Sign up': 'Sign in/Sign up',
  'Please enter your phone number to join us!':
    'Please enter your phone number to join us!',
  'Unregistered phone number will enter to sign up page':
    'Unregistered phone number will enter to sign up page',
  'Please enter phone number': 'Please enter phone number',
  'Please enter valid phone number': 'Please enter valid phone number',
  Next: 'Next',
  'Verify number': 'Verification',
  'Verification code sent! please enter it below.':
    'Verification code sent. Please enter it below.',
  'By continuing you agree to our Terms & Conditions and Online Policies.':
    'By continuing you agree to our Terms & Conditions and Online Policies.',
  'Set up password': 'Set up password',
  'New password': 'New password',
  'Re-type new password': 'Re-type new password',
  'Please enter at lease 6 characters with number, Upper letter, lower letter.':
    'Please enter at lease 6 characters with number, Upper letter, lower letter.',
  'Invaild password you entered, please try agian':
    'Invaild password you entered, please try agian',
  'Sign in': 'Sign in',
  'Enter password below to sign in': 'Enter password below to sign in',
  'Sign in with Dynamic Code': 'Sign in with Dynamic Code',
  'Forget password': 'Forget password',
  'Enter password': 'Enter password',
  Error: 'Error',
  Confirm: 'Confirm',
  'An unknown error occurred on the server':
    'An unknown error occurred on the server',
  'Sold out': 'Sold out',
  'Maximum inventory reached': 'Maximum inventory reached',
  'Seems no items in you cart...': 'Seems no items in you cart...',
  'Shop now': 'Shop now',
  Cart: 'Cart',
  items: 'items',
  Remove: 'Remove',
  Contact: 'Contact',
  Name: 'Name',
  Phone: 'Phone',
  Email: 'Email',
  Address: 'Address',
  'Please enter contact name': 'Please enter contact name',
  'Please enter contact phone': 'Please enter contact phone',
  'Please enter email': 'Please enter email',
  'Please enter your address': 'Please enter your address',
  City: 'City',
  'Postal Code': 'Postal Code',
  'Check out': 'Check out',
  'Shipping address': 'Shipping address',
  Shipping: 'Shipping',
  'Your Credit': 'Your Credit',
  Subtotal: 'Subtotal',
  PST: 'PST',
  GST: 'GST',
  Total: 'Total',
  Note: 'Note',
  Order: 'Order',
  'Use credit': 'Use credit',
  'If you have any requirements, please leave a message.':
    'If you have any requirements, please leave a message.',
  'The post code you entered is': 'The post code you entered is',
  'please confirm that the entry is correct.':
    'please confirm that the entry is correct.',
  'The total amount of goods in the shopping cart is less than':
    'The total amount of goods in the shopping cart is less than',
  'and free shipping must be over': 'and free shipping must be over',
  Apply: 'Apply',
  Undo: 'Undo',
  'Payment success': 'Payment success',
  'Go home': 'Go home',
  'Choose payment method': 'Choose payment method',
  'WeChat Pay': 'WeChat Pay',
  'Credit Card': 'Credit Card',
  'Card Number': 'Card Number',
  'Card Holder': 'Card Holder',
  Expiry: 'Expiry',
  CVC: 'CVC',
  'Save this card informations': 'Save this card informations',
  Pay: 'Pay',
  'Use previous credit card': 'Use previous credit card',
  'Add new credit card': 'Add new credit card',
  'Scan the QR Code below by turn on WeChat QR scanner to compelete payment.':
    'Scan the QR Code below by turn on WeChat QR scanner to compelete payment.',
  'QR Code Should Show Up Within 30 Seconds, Condition May Vary Depending On Internet Speed':
    'QR Code Should Show Up Within 30 Seconds, Condition May Vary Depending On Internet Speed',
  'Other payment method': 'Other payment method',
  Orders: 'Orders',
  Password: 'Password',
  Profile: 'Profile',
  'RORO VIP': 'RORO VIP',
  Redeem: 'Redeem',
  Giftcard: 'Giftcard',
  Referral: 'Referral',
  'Sign out': 'Sign out',
  WELCOME: 'WELCOME',
  Save: 'Save',
  Birthday: 'Birthday',
  'Your credit': 'Your credit',
  'ROROHOME credit can be used for all items purchase in ROROHOME online store.':
    'ROROHOME credit can be used for all items purchase in ROROHOME online store.',
  'All redeemed reward are nonrefundable, questions go to ':
    'All redeemed reward are nonrefundable, questions go to ',
  'learn more>': 'learn more>',
  'Enter your gift card PIN': 'Enter your gift card PIN',
  Redeem: 'Redeem',
  'Send your friend an exclusive invitation link first. If your friend signs up for ROROHOME.com through your invitation link, your friend will receive 100 points immediately. Also, if this friend completes his/her first order on ROROHOME, you can also earn 100 points when the order ships! Please check the invitation record for details. For your reward points, please verify your email firstly.':
    'Send your friend an exclusive invitation link first. If your friend signs up for ROROHOME.com through your invitation link, your friend will receive 100 points immediately. Also, if this friend completes his/her first order on ROROHOME, you can also earn 100 points when the order ships! Please check the invitation record for details. For your reward points, please verify your email firstly.',
  'Send invitation': 'Send invitation',
  Copy: 'Copy',
  Copied: 'Copied',
  'Your current credits': 'Your current credits',
  User: 'User',
  'Locked Rewards': 'Locked Rewards',
  'ROROHOME has established a membership growth project, aiming at providing a better user experience for loyal customers. Each customer will get corresponding points for every consumption in ROROHOME, and the user level will be upgraded as they reach a certain amount of points, and we will give upgraded users more discounts and rewards.':
    'ROROHOME has established a membership growth project, aiming at providing a better user experience for loyal customers. Each customer will get corresponding points for every consumption in ROROHOME, and the user level will be upgraded as they reach a certain amount of points, and we will give upgraded users more discounts and rewards.',
  'Birthday Surprise': 'Birthday Surprise',
  'Seasonal Gifts': 'Seasonal Gifts',
  'VIP Discount': 'VIP Discount',
  Locked: 'Locked',
  Unlocked: 'Unlocked',
  'Tire unlock': 'Tire unlock',
  Detail: 'Detail',
  'Members have to Fill in personal information under a personal account before receiving a birthday gift. The gift will be different in different months and VIP levels, and can only be claimed and used in that month. Members will get different level-up celebration gifts when the level is upgraded.':
    'Members have to Fill in personal information under a personal account before receiving a birthday gift. The gift will be different in different months and VIP levels, and can only be claimed and used in that month. Members will get different level-up celebration gifts when the level is upgraded.',
  'You will receive a special gift in every different season. The gift will be different in different months and VIP levels.':
    'You will receive a special gift in every different season. The gift will be different in different months and VIP levels.',
  'Users will enjoy a 5% discount on all products.':
    'Users will enjoy a 5% discount on all products.',
  'Tier Upgrade Gift': 'Tier Upgrade Gift',
  'Gift card': 'Gift card',
  'Payment methods': 'Payment methods',
  'Shipping policy': 'Shipping policy',
  'Term of use': 'Term of use',
  Privacy: 'Privacy',
  'Return policy': 'Return policy',
  Disclaimer: 'Disclaimer',
  'iOS App': 'iOS App',
  'Android App': 'Android App',
  'The Page you are looking for has been taken...':
    'The Page you are looking for has been taken...',
  'Try again': 'Try again',
  'ROROHOME is an online fresh food shopping app. We provides high-quality fresh meat, seafood, vegetables, fruits and more every day. Allows you to experience a high-quality life at home.':
    'ROROHOME is an online fresh food shopping app. We provides high-quality fresh meat, seafood, vegetables, fruits and more every day. Allows you to experience a high-quality life at home.',
  Comment: 'Comment',
  'Please complete the following options, left and submit your comments. Our custmer service will respond by email as soon as posible.':
    'Please complete the following options, left and submit your comments. Our custmer service will respond by email as soon as posible.',
  'Please left your comment here …': 'Please left your comment here …',
  'We have received your feedback!': 'We have received your feedback!',
  'Contact us': 'Contact us',
  'Empty orders list': 'Empty orders list',
  'Scan QR code and contact us by WeChat':
    'Scan QR code and contact us by WeChat',
  All: 'All',
  'PRICE RANGE': 'PRICE RANGE',
  Popular: 'Popular',
  Price: 'Price',
  DASHBOARD: 'DASHBOARD',
  Account: 'Account',
  'Redeem/Giftcard': 'Redeem/Giftcard',
  'Subscribe & Save': 'Subscribe & Save',
  fasterAndOnTime:
    'Faster and on time, We never forget your grocery require. Choose a Fresh pack below to subscribe now and get 5% to 8% off.',
  Subscribe: 'Subscribe',
  Subscribed: 'Subscribed',
  Unsubscribed: 'Unsubscribed',
  'Choose subscribe plan': 'Choose subscribe plan',
  Weekly: 'Weekly',
  'Choose delivery time': 'Choose delivery time',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Including: 'Including',
  onTimeDelivery:
    '定期送货日期既订购服务当天的次日。如需更改送货日期请在下单页面的备注里写下备注，或者与ROROHOME客服联系更改。',
  'Start date': 'Start date',
  'Delivery time': 'Delivery time',
  Plan: 'Plan',
  Contract: 'Contract',
  'Change plan': 'Change plan',
  pleaseChangePlan15:
    'Please change plan 15 days earlier before next delivery date. Our customer service may contact you to confirm.',
  Later: 'Later',
  youSubscribeServiceWillEnd:
    'Your subscribe service will be end. We hope to see you next time.',
  Ending: 'Ending',
  'Re-active subscribe plan': 'Re-active subscribe plan',
  onceYouConfirmReactive:
    'Once you confirm re-active plan, your subscribe plan will be continue charge',
  'Plan re-active': 'Plan re-active',
  welcomeBackYourSubscribePlan:
    'Welcome back! Your subscribe plan have been re-actived. We will provide you a better service. ',
  'Pack including:': 'Pack including:',
  Expired: 'Expired',
  'Choose a plan': 'Choose a plan',
  Monthly: 'Monthly',
  'Please choose a subscribe plan and delivery time.':
    'Please choose a subscribe plan and delivery time.',
  'Plan delivery at': 'Plan delivery at',
  'Order Summary': 'Order Summary',
  'Continue to check out': 'Continue to check out',
  Pending: 'Pending',
  Canceled: 'Canceled',
  'To Pay': 'To Pay',
  'Plan changed': 'Plan changed',
  Unsubscribe: 'Unsubscribe',
  'Please choose a plan you want to unsubscribe':
    'Please choose a plan you want to unsubscribe',
  'We are sorry to losing you': 'We are sorry to losing you',
  'Your subscribe service will be end.': 'Your subscribe service will be end.',
  'The network connection is abnormal, please try again later.':
    'The network connection is abnormal, please try again later.',
  'The network you are using cannot connect to our server, please check the network connection settings.':
    'The network you are using cannot connect to our server, please check the network connection settings.',
  welcomeBack:
    'Welcome back! Your subscribe plan have been re-actived. We will provide you a better service.',
  creditCardAreSecurely:
    'Credit card payment technology is provided by Stripe. All credit card are securely processed via Stripe.',
  Unpaid: 'Unpaid',
  'Continue to pay': 'Continue to pay',
  'Discard this order': 'Discard this order',
  'Enter email': 'Enter email',
  ROROHOME: 'ROROHOME',
  Today: 'Today',
  '7 days': '7 days',
  '30 days': '30 days',
  'Total Orders': 'Total Orders',
  'All Orders': 'All Orders',
  'Unfulfilled orders': 'Unfulfilled orders',
  'Payment status': 'Payment',
  'Delivery status': 'Delivery',
  Paid: 'Paid',
  Fulfilled: 'Fulfilled',
  Unfulfilled: 'Unfulfilled',
  Refund: 'Refund',
  'Print Receipt': 'Print',
  'Order Info': 'Order',
  'Delivery Info': 'Delivery',
  'Order Time': 'Time',
  'Point Used': 'Point Used',
  'Shipping Method': 'Shipping Method',
  'Customer Message': 'Customer Message',
  'Billing Address': 'Billing Address',
  'Shipping Address': 'Shipping Address',
  'Same as shipping address': 'Same as shipping address',
  Items: 'Items',
  'Refund money': 'Refund money',
  'Refund point': 'Refund point',
  'Refund amount': 'Refund amount',
  'Refund reason': 'Refund reason',
  'Add refund reason': 'Add refund reason',
  'Refund type': 'Refund type',
  Missing: 'Missing',
  Success: 'Success',
  'Publish Status': 'Publish Status',
  Sort: 'Sort',
  Stock: 'Stock',
  Published: 'Published',
  Unpublished: 'Unpublished',
  'Time descending': 'Time descending',
  'Time ascending': 'Time ascending',
  'Price ascending': 'Price ascending',
  'Price descending': 'Price descending',
  'In Stock': 'In Stock',
  'Out of Stock': 'Out of Stock',
  Inventory: 'Inventory',
  'Expired At': 'Expired At',
  'Image URL': 'Image URL',
  'Product detail': 'Product detail',
  'Product name': 'Product name',
  Vendor: 'Vendor',
  'Product type': 'Product type',
  Tag: 'Tag',
  'Sort value': 'Sort value',
  Taxes: 'Taxes',
  Excerpt: 'Excerpt',
  Variants: 'Variants',
  Options: 'Options',
  'Option name': 'Option name',
  'Click to add': 'Click to add',
  Values: 'Values',
  Option: 'Option',
  ID: 'ID',
  'Option 1': 'Option 1',
  'Option 2': 'Option 2',
  'Option 3': 'Option 3',
  'Compared Price': 'Compared Price',
  'Cost Price': 'Cost Price',
  Price: 'Price',
  'Service Charge': 'Service Charge',
  'Service Title': 'Service Title',
  Image: 'Image',
  Size: 'Size',
  'Inventory Count': 'Inventory Count',
  'New Product': 'New Product',
  'Icon URL': 'Icon URL',
  'Banner URL': 'Banner URL',
  'Collection detail': 'Collection detail',
  'Collection name': 'Collection name',
  'Chinese name': 'Chinese name',
  Type: 'Type',
  'Meet all conditions': 'Meet all conditions',
  Conditions: 'Conditions',
  Condition: 'Condition',
  Attribute: 'Attribute',
  Operator: 'Operator',
  Value: 'Value',
  'New Collection': 'New Collection',
  'Min expense': 'Min expense',
  'Service level': 'Service level',
  'Export to CSV': 'Export to CSV',
  'Delivery name': 'Delivery name',
  'Exclude Postal Code': 'Exclude Postal Code',
  'New Shipping Rate': 'New Shipping Rate',
  Balance: 'Balance',
  Point: 'Point',
  'User Info': 'User Info',
  Subscribed: 'Subscribed',
  'Choose Image': 'Choose Image',
  Submit: 'Submit',
  'No file chosen': 'No file chosen',
  Copy: 'Copy',
  Copied: 'Copied',
  'Select a file': 'Select a file',
  Close: 'Close',
  'Please input 4 Gift Card#': 'Please input 4 Gift Card#',
  Check: 'Check',
  true: 'true',
  false: 'false',
  Qty: 'Qty',
  'Products purchases': 'Products purchases',
  'TOP 20': 'TOP 20',
  Home: 'Home',
  Product: 'Product',
  Item: 'Product',
  Collection: 'Collection',
  Asset: 'Asset',
  Report: 'Report',
  Member: 'Member',
  Delivery: 'Delivery',
  'Giftcard Used': "Giftcard Used",
  "signOut": "Sign out",
  "changePassword": "Change password",
  "changeStore": "Change store",
  "next": "Next",
  "chooseStore": "Choose store",
  "chooseAndClickStore": "Select and click the store below, then click the button to enter the management",
  "account": "Account",
  "store": "Store",
  "enterNewPassword": "Enter new password",
  "enterNewPasswordAgain": "Enter new password again",
  "forgetPassword": "Forgot password?",
  "findPassword": "Find password",
  "haveSentCode": "We have sent an email to your email, please enter the verification code you received below.",
  "Product ID": "Product ID",
  "Collection ID": "Collection ID",
  "Advertisement": "Advertisement",
  "Image URL": "Image URL",
  "Chinese Description": "Chinese Description",
  "Location": "Location",
  "Description": "Description",
  "Title": "Title",
  "URL": "URL",
  "Detail": "Detail",
  "Position": "Position",
  "New Advertisement": "New Advertisement",
}
