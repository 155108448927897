import { useUI } from '@components/ui/context'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import * as server from '@lib/server'
import { useMemo, useEffect, useState, useCallback } from 'react'

import { Stripe, loadStripe } from '@stripe/stripe-js'
import { isInDev } from '@lib/server'

export const useLoading = () => {
  const { formatMessage } = useIntl()
  const t = (id) => formatMessage({ id })
  const { showLoading, hideLoading, showToast } = useUI()

  return async (action: Function, whenError: string = null) => {
    showLoading()
    try {
      const result = await action()
      hideLoading()
      return result
    } catch (error) {
      hideLoading()
      let errorInfo = whenError || t('An unknown error occurred on the server')
      try {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.error
          ) {
            errorInfo = error.response.data.message.error
          } else if (error.response.data && error.response.data.error) {
            errorInfo = JSON.stringify(error.response.data.error)
          } else {
            errorInfo = JSON.stringify(error.response.data || errorInfo)
          }
        } else {
          errorInfo = error.toString()
        }
      } catch (e) {}

      showToast('error', t('Error'), errorInfo, '')
    }
    return null
  }
}

export const useT = () => {
  const { formatMessage } = useIntl()
  const t = (id) => formatMessage({ id })
  return t
}

export const getTotalPrice = (cart) => {
  if (!cart || cart.length === 0) return 0
  let totalPrice = 0
  for (let i = 0; i < cart.length; i += 1) {
    const item = cart[i]
    totalPrice += item.quantity * item.variant.price
  }
  return totalPrice
}

export const getOrderPriceSummary = (cart, shippingRate, usedBalance) => {
  if (!cart || cart.length === 0) return null
  const order = {}
  order['pst'] = cart.reduce((acc, obj) => {
    if (obj.pst) {
      acc += 0.07 * obj.variant.price * obj.quantity
    }
    return acc
  }, 0)

  order['gst'] = cart.reduce((acc, obj) => {
    if (obj.gst) {
      acc += 0.05 * obj.variant.price * obj.quantity
    }
    return acc
  }, 0)

  order['serviceCharge'] = cart.reduce((acc, obj) => {
    acc += (obj.variant.serviceCharge ?? 0) * obj.quantity
    return acc
  }, 0)

  order['subTotal'] = cart.reduce((acc, obj) => {
    acc += obj.variant.price * obj.quantity
    return acc
  }, 0)

  order['total'] =
    order['subTotal'] +
    shippingRate.price +
    order['serviceCharge'] +
    order['gst'] +
    order['pst'] -
    (usedBalance || 0)
  return order
}

export const canUseGiftCard = (cart) => {
  if (!cart || cart.length <= 0) return true

  for (let i = 0; i < cart.length; i++) {
    const product = cart[i]
    if (product.productType == 'giftcard') {
      return false
    }
  }
  return true
}

export const isContactComplete = (contact) => {
  if (
    !contact ||
    !contact.name ||
    !contact.phone ||
    !contact.email ||
    !contact.street ||
    !contact.city ||
    !contact.postalCode
  ) {
    return false
  }
  return true
}

export const useCart = () => {
  const [mounted, setMounted] = useState(false)
  const { data, error, mutate } = useSWR(
    mounted ? '/cart' : null,
    async () => await server.getCart()
  )
  useEffect(() => {
    setMounted(true)
  }, [])
  return { cart: data, mutate }
}

export const useUser = () => {
  const [mounted, setMounted] = useState(false)
  const { data, error, mutate } = useSWR(
    mounted ? '/user' : null,
    async () => await server.getSelf()
  )
  useEffect(() => {
    setMounted(true)
  }, [])
  return { user: data, mutate }
}

export const useClientSWR = (
  key: string | Function,
  fn: any,
  options: any = undefined
) => {
  const [mounted, setMounted] = useState(false)
  const keyValue = useMemo(() => {
    if (!mounted) return null
    if (typeof key === 'function') {
      return key()
    }
    return key
  }, [mounted])
  const { data, error } = useSWR(
    mounted ? keyValue : null,
    async () => await fn(),
    options
  )
  useEffect(() => {
    setMounted(true)
  }, [])
  return { data, error }
}

let stripePromise: Promise<Stripe | null>
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      isInDev
        ? 'pk_test_iD9TaQvK9508Ovv6Vony6qhl'
        : 'pk_live_YSixrzalpsQFDY08zke4yz18'
    )
    // stripePromise = loadStripe("pk_test_iD9TaQvK9508Ovv6Vony6qhl");
  }
  return stripePromise
}

export const useMediaQuery = () => {
  const width = 640
  const [isMobile, setTargetReached] = useState(false)

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true)
    } else {
      setTargetReached(false)
    }
  }, [])

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    try {
      media.addEventListener('change', updateTarget)
    }
    catch(e) {
      media.addListener(updateTarget)
    }

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true)
    }

    return () => media.removeEventListener('change', updateTarget)
  }, [])

  return isMobile
}

export const useMenu = () => {
  const { data } = useSWR('/menu', async () => {
    const collections = await server.getCollections()
    return collections
  })
  return data
}

export const footData = [
  {
    title: 'About Us',
    items: [
      {
        href: '/page/ourstory',
        name: 'Our story',
      },
      {
        href: '/page/business',
        name: 'Business',
      },
      {
        href: '/page/career',
        name: 'Career',
      },
    ],
  },
  {
    title: 'Service',
    items: [
      {
        href: '/contact',
        name: 'Contact us',
      },
      {
        href: '/about/giftcard',
        name: 'Gift card',
      },
      {
        href: '/about/payment',
        name: 'Payment methods',
      },
      {
        href: '/about/shipping',
        name: 'Shipping policy',
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      {
        href: '/about/term',
        name: 'Term of use',
      },
      {
        href: '/about/disclaimer',
        name: 'Disclaimer',
      },
      {
        href: '/about/privacy-policy',
        name: 'Privacy',
      },
      {
        href: '/about/refund',
        name: 'Return policy',
      },
    ],
  },
  {
    title: 'Mobile App',
    items: [
      {
        href: '/download',
        name: 'iOS App',
      },
      {
        href: '/download',
        name: 'Android App',
      },
    ],
  },
]

export enum SubscriptionStatus {
  none = 'none',
  subscribed = 'subscribed',
  ending = 'ending',
  canceled = 'canceled',
  pending = 'pending',
}

export const getSubscriptionStatus = (
  subscription: any
): SubscriptionStatus => {
  if (
    subscription.subscribed == null ||
    subscription.subscribed.status == null
  ) {
    return SubscriptionStatus.none
  }
  if (subscription.subscribed.status == 'active') {
    if (subscription.subscribed.cancelAtPeriodEnd == true) {
      return SubscriptionStatus.ending
    }
    return SubscriptionStatus.subscribed
  } else if (subscription.subscribed.status == 'canceled') {
    return SubscriptionStatus.canceled
  }
  return SubscriptionStatus.pending
}

export const getPlanName = (variant: any, t: any): string => {
  if (!variant) return t('Choose a plan')
  const pre = variant.intervalCount != 1 ? `${variant.intervalCount} ` : ''
  let sub = ''

  switch (variant.interval) {
    case 'week':
      sub = t('Weekly')
      break
    case 'month':
      sub = t('Monthly')
      break
  }
  return pre + sub
}

export const formatDate = (date: Date): string => {
  return date.toLocaleString()
}

export const formatDateYMD = (date: Date): string => {
  return date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear()
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
