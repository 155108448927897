import React, { FC, useEffect } from 'react'
import { Navbar } from '@components/common'
import { Loading, Modal, Dialog } from '@components/ui'
import { useUI } from '@components/ui/context'
import { useIntl } from 'react-intl'
import SmartBanner from 'react-smartbanner';
import { useMediaQuery } from '@utils'

interface Props {
}

const Layout: FC<Props> = ({ children }) => {
    const { formatMessage } = useIntl()
    const t = id => formatMessage({ id })
    const {
        displayLoading,
        displayToast,
        displayType,
        title,
        description,
        icon,
        hideToast,
    } = useUI()

    useEffect(() => {
        if (displayType == 'icon' && displayToast == true) {
            setTimeout(function () { hideToast() }, 1000);
        }
    }, [displayType, displayToast])
    const isMobile = useMediaQuery()
    return (
        <div>
            {/* { isMobile && <SmartBanner title={'ROROHOME'} daysHidden={1} daysReminder={1} icon={'/touch-icon.jpg'} /> } */}
            <Navbar></Navbar>
            <main className="fit relative">{children}</main>
            <Loading open={displayLoading}>
            </Loading>
            {
                displayType === "error" &&
                <Modal open={displayToast}>
                    <div className="flex flex-col items-center p-4">
                        <h1 className='font-bold text-lg mb-4'>{title}</h1>
                        <span className='text-sm'>{description}</span>
                        <button className='text-white font-semibold rounded-3xl bg-color33 w-full mt-4 p-2' onClick={() => hideToast()}>{t("Confirm")}</button>
                    </div>
                </Modal>
            }
            {
                displayType === "icon" &&
                <Dialog open={displayToast}>
                    <div className="flex flex-col items-center p-4 justify-center">
                        <i className={`${icon} text-white text-7xl`} />
                        <span className='text-white font-semibold pt-4'>{title}</span>
                    </div>
                </Dialog>
            }
        </div>
    )

}

export default Layout
