
interface Props {
    style?: any
    icon: string
    className?: string
}

const Icon: React.FC<Props> = (props) => {
    return <i style={props.style} className={`${props.icon} ${props.className}`} />;
};

export default Icon;
