import Select from "react-select";
import React, { useState } from 'react';
import { useRouter } from 'next/router'

const options = [
    { value: 'en', label: 'English' },
    { value: 'zh', label: '中文' },
];

const customStyles = {

    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#E5E5E5',
        borderColor: 'transparent'
    }),

    singleValue: (provided, state) => ({
        ...provided,
        color: '#333333'
    }),
}

interface Props {
    unique: string
}

const I18nWidget: React.FC<Props> = (props) => {
    const router = useRouter()
    const { locale } = router
    const currentOption = options.find(option => option.value == locale)
    const [selectedOption, setSelectedOption] = useState(currentOption);
    return (
        <>
            <Select
                id={props.unique}
                instanceId={props.unique}
                styles={customStyles}
                defaultValue={selectedOption}
                onChange={(option) => {
                    setSelectedOption(option)
                    router.replace({ pathname: router.pathname, query: router.query }, null, { locale: option.value });
                }}
                options={options}
                isSearchable={false}
                theme={theme => ({
                    ...theme,
                    borderRadius: '4px',
                    colors: {
                        ...theme.colors,
                        primary25: '#e5e5e5',
                        primary: '#333333',
                    },
                })}
            />
        </>
    )
}

export default I18nWidget
