import React, { FC, useMemo } from 'react'


export interface State {
    displayLoading: boolean,
    displayToast: boolean,
    displayType: string, //error, success, warning, icon
    title: string,
    description: string
    icon: string
}

const initialState = {
    displayLoading: false,
    displayToast: false,
    displayType: 'error',
    title: '',
    description: '',
    icon: ''
}

type Action =
    | {
        type: "SHOW_LOADING"
    }
    | {
        type: "HIDE_LOADING"
    }
    | {
        type: "SHOW_TOAST",
        displayType: string,
        title: string,
        description: string,
        icon: string
    }
    | {
        type: "HIDE_TOAST"
    }


export const UIContext = React.createContext<State | any>(initialState)
UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
    switch (action.type) {
        case 'SHOW_LOADING': {
            return {
                ...state,
                displayLoading: true
            }
        }
        case 'HIDE_LOADING': {
            return {
                ...state,
                displayLoading: false
            }
        }
        case 'SHOW_TOAST': {
            return {
                ...state,
                displayToast: true,
                displayType: action.displayType,
                title: action.title,
                description: action.description,
                icon: action.icon
            }
        }
        case 'HIDE_TOAST': {
            return {
                ...state,
                displayToast: false
            }
        }
    }
}

export const UIProvider: FC = (props) => {

    const [state, dispatch] = React.useReducer(uiReducer, initialState)
    const showLoading = () => dispatch({ type: 'SHOW_LOADING' })
    const hideLoading = () => dispatch({ type: 'HIDE_LOADING' })
    const showToast = (displayType: string, title: string, description: string, icon: string) => dispatch({ type: 'SHOW_TOAST', displayType, title, description, icon })
    const hideToast = () => dispatch({ type: 'HIDE_TOAST' })
    const value = useMemo(() => ({
        ...state,
        showLoading,
        hideLoading,
        showToast,
        hideToast
    }), [state])
    return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
    const context = React.useContext(UIContext)
    if (context === undefined) {
        throw new Error(`useUI must be used within a UIProvider`)
    }
    return context
}

export const ManagedUIContext: FC = ({ children }) => (
    <UIProvider>
        {children}
    </UIProvider>
)