import { useState,useEffect} from 'react';
import { useT } from '@utils'

import { useUI } from '@components/ui/context'

import { Icon} from '@components/ui'
import * as server from '@lib/server'


interface Props {
    item: any,
}


const GiftcardModalSidePanel: React.FC<Props> = ({item}) => {

    const t = useT();
    const { showToast} = useUI()

    const [editItem,setEditItem] = useState(item)

    const handleOnChangeActive =async () => {
		let data
        if(editItem.status == 'active'){
            data={status:'inactive'}
        }else if(editItem.status == 'inactive'){
            data={status:'active'}
        }else{
            showToast('icon', t('Can not edit'), 'used status', 'icon-cross1')
            return
        }

		let updatedData = await server.activeGiftCard(item.id, data)
		if(updatedData){
			setEditItem(updatedData);
		}

	}

    useEffect(() => {
        setEditItem(item)
      }, [item])

    return (
        <div className="flex flex-col mt-4 ">

            <div className='flex flex-row justify-between items-center my-2 w-full px-2'>
                <div className='flex flex-row'>
                    <Icon icon="icon-Icon_Tag" className="text-xl pr-2"></Icon>
                    <div >{t('Activate')}</div>
                </div>

                <div>{editItem.status == 'active'? <button className='w-24 rounded py-1 bg-green' onClick={handleOnChangeActive}>{t('Active')}</button> : <button className='w-24 rounded py-1 bg-colore5' onClick={handleOnChangeActive} > {t(editItem.status)}</button>}</div>
            </div>

            <div className='flex flex-row justify-between items-center my-2 w-full px-2'>
                <div className='flex flex-row'>
                    <Icon icon="icon-Icon_Calendar" className="text-xl pr-2"></Icon>
                    <div>{t('Update time')}</div>
                </div>
                <div>{editItem.updatedAt?editItem.updatedAt.substr(0,10):'---'}</div>
            </div>


            <div className='flex flex-row justify-between items-center my-2 w-full px-2'>
                <div className='flex flex-row'>
                    <Icon icon="icon-Icon_Discount" className="text-xl pr-2"></Icon>
                    <div>{t('UseID')}</div>
                </div>

                <div>{editItem.userId?editItem.userId:'---'}</div>
            </div>

            <div className='flex flex-row justify-between items-center my-2 w-full px-2'>
                <div className='flex flex-row'>
                    <Icon icon="icon-Icon_Amount" className="text-xl pr-2"></Icon>
                    <div>{t('Value')}</div>
                </div>

                {editItem.value? <span>$CAD{(editItem.value/100).toFixed(2)}</span> : <span >- -</span> }

            </div>


            <div className='flex flex-row justify-between items-center my-2 w-full px-2'>
                <div className='flex flex-row'>
                    <Icon icon="icon-Icon_Credit-card-2" className="text-xl pr-2"></Icon>
                    <div>{t('Giftcard ID')}</div>
                </div>

                <div>{editItem.id}</div>

            </div>

        </div>


    )
}

export default GiftcardModalSidePanel
