import axios from 'axios'

export let isInDev = false

let PREFIX = isInDev ? 'dev_' : 'prod_'
let SERVER_URL = isInDev
  ? 'https://dev.gearts.com/api/v1'
  : 'https://core.gearts.com/api/v1'

const CLIENT_API_KEY: string = 'tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo'
const PROGRAM_API_KEY: string = 'UMgS0VZLS0tLS0KTUlJQ0lqQU5CZ2'

const TOKEN_KEY = 'gearts_token'

let coreApi = axios.create({ baseURL: 'https://core.gearts.com/api/v1' })


function getApi() {
  let store = getStore();
  let api = axios.create({ baseURL: `https://${store}.gearts.com/api/v1` })
  return api
}

// let previousDomain = null;
// let currentDomain = null;
// export function setDomain(domain: string) {
//   previousDomain = currentDomain;
//   currentDomain = domain;
//   api = axios.create({ baseURL: `https://${domain}.gearts.com/api/v1` })
// }

// export function setPreviousDomain() {
//   if (previousDomain != null) setDomain(previousDomain);
// }

export function currentEpoch(): number {
  return ~~(new Date().getTime() / 1000)
}

export async function getAds() {
  let res = await getApi().get('/advertisements')
  return res.data
}

export async function getProductsGroupedByCollection() {
  let _params = { group_by_collection: true }
  let res = await getApi().get(`/products`, { params: _params })
  let productsCollections = res.data
  return productsCollections
}

export async function getCollections() {
  let _params = { published: true }
  let res = await getApi().get('/collections', { params: _params })
  return res.data
}

export async function getAllCollections() {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().get('/collections', { headers: headers })
  return res.data
}


export async function getAllAdvertisements() {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().get('/advertisements', { headers: headers })
  return res.data
}

export async function getAllAssets() {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  const params = {
    user_id: 'admin',
  }
  let res = await getApi().get('/storage/assets', { headers: headers, params })
  return res.data
}

export async function getAllUsers() {
  const headers = {
    'program-api-key': PROGRAM_API_KEY,
  }
  let res = await getApi().get('/users', { headers: headers })
  return res.data
}

export async function getUser(id: string) {
  const headers = {
    'program-api-key': PROGRAM_API_KEY,
  }
  let res = await getApi().get(`/users/${id}`, { headers: headers })
  return res.data
}

export async function getCollection(id: string) {
  let res = await getApi().get(`/collections/${id}`)
  return res.data
}

export async function getAdvertisement(id: string) {
  let res = await getApi().get(`/advertisements/${id}`)
  return res.data
}


export async function getProductsInCollection(id: string) {
  let _params = {
    collection_id: id,
    published: true,
    brief: true,
    order: 'manual',
  }
  let res = await getApi().get('/products', { params: _params })
  return res.data
}

export async function getAllProducts() {
  let _params = {
    order: 'manual',
  }

  let res = await getApi().get('/products', { params: _params })
  return res.data
}

export async function getProduct(id: string) {
  let res = await getApi().get(`/products/${id}`)

  return res.data
}

export async function getHtml(name: string, locale: string) {
  let res = await getApi().get(
    '/static-files/' + name + (locale == 'zh' ? '-zh' : '') + '.html'
  )
  return res.data.data
}

export async function searchProducts(keyword: string) {
  let _params = {
    keyword: keyword,
    published: true,
    brief: true,
    order: 'manual',
  }
  let res = await getApi().get('/products', { params: _params })

  return res.data
}

export async function hasPassword(areaCode: string, phone: string) {
  const headers = {
    'client-api-key': CLIENT_API_KEY,
  }
  let _params = {
    area_code: areaCode,
    phone: phone,
  }
  let res = await coreApi.get('/login/password', {
    params: _params,
    headers: headers,
  })

  return res.data == 'true' || res.data == true
}

export async function signIn(zone: string, phone: string, password: string) {
  const headers = {
    'client-api-key': CLIENT_API_KEY,
  }

  let res = await coreApi.post(
    '/login/phone',
    {
      password: password,
      areaCode: zone,
      phone: phone,
    },
    { headers: headers }
  )
  saveToken(res.data)
  return res.data
}

export async function emailSignIn(email: string, password: string) {
  const headers = {
    'client-api-key': CLIENT_API_KEY,
  }

  let res = await coreApi.post(
    '/login/email',
    {
      email: email,
      password: password,
    },
    { headers: headers }
  )
  saveToken(res.data)
  return res.data
}


export async function saveEmail(email: string) {
  localStorage.setItem(PREFIX + "email", email)
}

export function getEmail(): string {
  return localStorage.getItem(PREFIX + "email")
}


export async function saveStore(domain: string) {
  localStorage.setItem(PREFIX + "store", domain)
}

export function getStore(): string {
  return localStorage.getItem(PREFIX + "store")
}


async function saveToken(token) {
  localStorage.setItem(PREFIX + TOKEN_KEY, JSON.stringify(token))
}

export async function getToken() {
  let tokenString = localStorage.getItem(PREFIX + TOKEN_KEY)
  let token = null
  if (tokenString) {
    token = JSON.parse(tokenString)
    if (token && token.expires_in < currentEpoch() + 60) {
      if (token.refresh_token_expires_in > currentEpoch() + 60) {
        token = await refreshToken(token.refresh_token)
        saveToken(token)
      } else {
        token = null
      }
    }
  }
  return token
}

async function refreshToken(refreshToken: string) {
  let res = await coreApi.post('/login/refresh', {
    refresh_token: refreshToken,
  })
  return res.data
}

export async function sendcode(email: string) {
  const headers = {
    'client-api-key': CLIENT_API_KEY,
  }

  let res = await coreApi.post(
    '/login/code',
    {
      email: email,
    },
    { headers: headers }
  )
  return true
}

export async function signInCode(
  email: string,
  code: string,
  referralCode: string = null
) {
  const headers = {
    'client-api-key': CLIENT_API_KEY,
  }

  let res = await coreApi.post(
    '/login/email',
    {
      code: code,
      email: email,
    },
    { headers: headers }
  )
  saveToken(res.data)
  return res.data
}

export async function setPassword(password: string) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await coreApi.patch(
    `/auth/users/${token.userId}/password`,
    {
      password: password,
      method: token.method,
    },
    { headers: headers }
  )
  return true
}

export async function getSelfOrders() {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let _params = {
    transaction_status: 'paid',
    user_id: token.userId,
  }
  let res = await getApi().get('/orders', {
    headers: headers,
    params: _params,
  })
  return res.data
}

export async function getOrders(data) {
  const headers = {
    'program-api-key': PROGRAM_API_KEY,
  }

  let res = await getApi().get('/orders', {
    headers: headers,
    params: data,
  })
  return res.data
}

export async function getOrder(id: string) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().get(`/orders/${id}`, {
    headers: headers,
  })
  return res.data
}

export async function updateOrder(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().patch(`/orders/${id}`, data, {
    headers: headers,
  })
  return res.data
}

export async function updateProduct(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().patch(`/products/${id}`, data, {
    headers: headers,
  })
  return res.data
}

export async function updateCollection(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().patch(`/collections/${id}`, data, {
    headers: headers,
  })
  return res.data
}


export async function updateAdvertisement(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().put(`/advertisement/${id}`, data, {
    headers: headers,
  })
  return res.data
}

export async function updateShippingRate(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().put(`/shipping-rates/${id}`, data, {
    headers: headers,
  })
  return res.data
}

export async function createCollection(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().put(`/collections/${id}`, data, {
    headers: headers,
  })
  return res.data
}


export async function createAdvertisement(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().put(`/advertisements/${id}`, data, {
    headers: headers,
  })
  return res.data
}

export async function createProduct(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().put(`/products/${id}`, data, {
    headers: headers,
  })
  return res.data
}

export async function refundTransaction(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().post(`/payment/transactions/${id}/refund`, data, {
    headers: headers,
  })
  return res.data
}

export async function refundOrder(id: string, data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().post(`/orders/${id}/refund`, data, {
    headers: headers,
  })
  return res.data
}

export async function getSelfSubscriptions() {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let _params = {
    user_id: token.userId,
  }
  let res = await getApi().get('/subscription-packages', {
    headers: headers,
    params: _params,
  })
  return res.data
}

export async function feedback(
  name: string,
  phone: string,
  email: string,
  description: string
) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let _params = {
    name,
    phone,
    email,
    description,
    type: 'suggestion',
  }
  let res = await getApi().post(
    `/users/${token.userId ? token.userId : 'guest'}/feedback`,
    _params,
    { headers: headers }
  )
  return true
}

export async function getSelf() {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().get(`/users/${token.userId}`, { headers: headers })
  let user = res.data
  return user
}

export async function editProfile(values: {}) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let _params = {}

  if (values['birthday']) {
    _params['birthday'] = values['birthday']
  }

  if (values['name']) {
    _params['name'] = values['name']
  }

  if (values['address']) {
    let _address = {
      name: values['name'],
      phone: values['phone'],
      email: values['email'],
      street: values['address'],
      city: values['city'],
      province: 'BC',
      postalCode: values['postalCode'],
      country: 'Canada',
    }
    _params['addresses'] = [_address]
  }

  let res = await getApi().patch(`/users/${token.userId}`, _params, {
    headers: headers,
  })
  let user = res.data
  return user
}

export async function redeemGiftCard(code: string) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().post(
    `/giftcards/card/redeem`,
    {
      userId: token.userId,
      pin: code,
    },
    { headers: headers }
  )

  return true
}

export async function updateCart(cart) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().patch(`/users/${token.userId}/cart`, cart, {
    headers: headers,
  })

  return true
}

export async function getCart() {
  const token = await getToken()
  if (!token) return []
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().get(`/users/${token.userId}/cart`, {
    headers: headers,
  })

  return res.data
}

export async function getAllShippingRates() {
  const token = await getToken()
  if (!token) return []
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().get('/shipping-rates', {
    headers: headers,
  })
  return res.data
}

export async function getShippingRates(postalCode) {
  const token = await getToken()
  if (!token) return []
  const headers = {
    Authorization: token.access_token,
  }
  let _params = {
    postal_code: postalCode,
  }
  let res = await getApi().get('/shipping-rates', {
    params: _params,
    headers: headers,
  })
  return res.data
}

export async function getShippingRate(id: string) {
  const token = await getToken()
  if (!token) return []
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().get(`/shipping-rates/${id}`, {
    headers: headers,
  })
  return res.data
}

export async function placeOrder(
  cart,
  address,
  shippingRate,
  requirement,
  usedBalance
) {
  const token = await getToken()

  if (!token) return null
  const headers = {
    Authorization: token.access_token,
  }

  let data = {
    userId: token.userId,
    platform: 'web',
    address: address,
    products: cart.map((e) => {
      return {
        id: e.id,
        variantId: e.variant.id,
        quantity: e.quantity,
      }
    }),
    requirement: requirement,
    shippingRateId: shippingRate.id,
  }
  if (usedBalance > 0) data['usedBalance'] = usedBalance

  let res = await getApi().post('/orders', data, { headers: headers })
  return res.data
}

export async function getStripeCustomer() {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().get(`/payment/users/${token.userId}`, {
    headers: headers,
  })

  return res.data
}

export async function payWithStripeAction(
  transactionId: string,
  paymentMethodId: string,
  isSave: boolean = true,
  usePrevious: boolean = false
) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let data = { id: transactionId, toSaveCard: isSave }

  if (!usePrevious) {
    data['paymentMethodId'] = paymentMethodId
  }

  let res = await getApi().post(
    `/payment/transactions/${data['id']}/pay/stripe`,
    data,
    {
      headers: headers,
    }
  )
  if (res.data.status == 'succeeded') return true
  return false
}

export async function payWithWechatAction(transactionId: string) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let data = {
    id: transactionId,
    platform: 'web',
    body: 'ROROHOME 订单',
  }

  let res = await getApi().post(
    `/payment/transactions/${data['id']}/pay/wechat`,
    data,
    {
      headers: headers,
    }
  )
  return res.data.code_url
}

export async function isTransactionPaid(transactionId: string) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().get(`/payment/transactions/${transactionId}`, {
    headers: headers,
  })
  if (res.data && res.data.status == 'paid') return true

  return false
}

export async function trackUser() {
  const token = await getToken()
  if (!token) return
  const headers = {
    Authorization: token.access_token,
  }

  const data = {
    platform: 'web',
    os: 'browser',
    version: '3.0',
  }

  await getApi().patch(`/track/users/${token.userId}`, data, {
    headers: headers,
  })

  return
}

export async function updateUserSubscription(data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().patch(`/users/${token.userId}/subscribed`, data, {
    headers: headers,
  })

  return res.data
}

export async function placeSubscription(data) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let res = await getApi().post(`/users/${token.userId}/subscribed`, data, {
    headers: headers,
  })

  return res.data
}

export async function postImage(file, name) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let _params = {
    extention: name.split('.').pop().toLowerCase(),
    user_id: 'admin',
  }

  let res = await getApi().get('/storage/assets/sign', {
    headers: headers,
    params: _params,
  })

  if (res.data) {
    let signedurl = res.data.signedUrl
    let url = res.data.url

    let result = await axios.put(signedurl, file)
    return url
  }
}

export async function activeGiftCard(id: string, status: {}) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let card
  let res = await getApi().patch(`/giftcards/${id}`, status, {
    headers: headers,
  })

  if (res.data) {
    card = await getApi().get(`/giftcards/${id}`, {
      headers: headers,
    })
  }

  return card.data
}

export async function getGiftcard(id: string) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }

  let res = await getApi().get(`/giftcards/${id.toUpperCase()}`, {
    headers: headers,
  })

  return res.data
}

export async function getOrdersSummary(values) {
  const token = await getToken()
  const headers = {
    Authorization: token.access_token,
  }
  let _params = values

  let res = await getApi().get('orders/:report/summary', {
    headers: headers,
    params: _params,
  })
  return res.data
}


export async function getTenants(email: string) {
  const headers = {
    'client-api-key': CLIENT_API_KEY,
  }

  let res = await coreApi.get(
    '/login/tenant',
    { headers: headers, params: { email: email.trim().toLowerCase() } }
  )
  return res.data
}
