import { FC, useEffect } from 'react'
import NextHead from 'next/head'
import { useRouter } from "next/router"
import * as server from '@lib/server'

const Head: FC = () => {
  const router = useRouter()
  useEffect(() => {
    server.getToken().then((token) => {
      console.log(router.pathname);
      if (!token && !router.pathname.startsWith('/login')) {
        router.replace('/login/email')
      }
    })
  }, [])
  return (
    <>
      <NextHead>
        <title>{"GEARTS"}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="apple-itunes-app" content="app-id=1511817790" />
        <meta name="google-play-app" content="app-id=com.rorohome.android" />
        <link rel="apple-touch-icon" sizes="180x180" href="/touch-icon.jpg" />
        <link rel="android-touch-icon" sizes="180x180" href="/touch-icon.jpg" />
        {/* <link rel="manifest" href="/site.webmanifest" key="site-manifest" /> */}
      </NextHead>
    </>
  )
}

export default Head
